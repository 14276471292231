<template>
  <ul class="s-pagination">
    <li :disabled="current === 1">
      <router-link :to="{ query: { page: 1 } }">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.1816 20.3398C17.1816 20.5352 17.2598 20.7109 17.4062 20.8574L24.7598 27.9375C24.8965 28.0742 25.0723 28.1523 25.2773 28.1523C25.6777 28.1523 25.9902 27.8398 25.9902 27.4297C25.9902 27.2246 25.9121 27.0586 25.7852 26.9219L18.9395 20.3398L25.7852 13.748C25.9121 13.6113 25.9902 13.4355 25.9902 13.2402C25.9902 12.8301 25.6777 12.5273 25.2773 12.5273C25.0723 12.5273 24.8965 12.5957 24.7598 12.7324L17.4062 19.8125C17.2598 19.959 17.1816 20.1348 17.1816 20.3398Z"
            :fill="current === 1 ? fillColor.disabled : fillColor.normal"
          />
          <line
            x1="14.6"
            y1="12.6"
            x2="14.6"
            y2="28.4"
            :stroke="current === 1 ? fillColor.disabled : fillColor.normal"
            stroke-width="1.2"
            stroke-linecap="round"
          />
        </svg>
      </router-link>
    </li>
    <li :disabled="current <= 1">
      <router-link :to="{ query: { page: current - 1 } }">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.1816 20.3398C15.1816 20.5352 15.2598 20.7109 15.4062 20.8574L22.7598 27.9375C22.8965 28.0742 23.0723 28.1523 23.2773 28.1523C23.6777 28.1523 23.9902 27.8398 23.9902 27.4297C23.9902 27.2246 23.9121 27.0586 23.7852 26.9219L16.9395 20.3398L23.7852 13.748C23.9121 13.6113 23.9902 13.4355 23.9902 13.2402C23.9902 12.8301 23.6777 12.5273 23.2773 12.5273C23.0723 12.5273 22.8965 12.5957 22.7598 12.7324L15.4062 19.8125C15.2598 19.959 15.1816 20.1348 15.1816 20.3398Z"
            :fill="current <= 1 ? fillColor.disabled : fillColor.normal"
          />
        </svg>
      </router-link>
    </li>

    <div class="s-pagination__center">
      {{ current }}<span>/</span>{{ totalPage }}
    </div>

    <li :disabled="current >= totalPage">
      <router-link :to="{ query: { page: current + 1 } }">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.3438 20.3398C25.3438 20.1348 25.2656 19.959 25.1191 19.8125L17.7656 12.7324C17.6289 12.5957 17.4531 12.5273 17.248 12.5273C16.8477 12.5273 16.5352 12.8301 16.5352 13.2402C16.5352 13.4355 16.6133 13.6113 16.7402 13.748L23.5859 20.3398L16.7402 26.9219C16.6133 27.0586 16.5352 27.2246 16.5352 27.4297C16.5352 27.8398 16.8477 28.1523 17.248 28.1523C17.4531 28.1523 17.6289 28.0742 17.7656 27.9375L25.1191 20.8574C25.2656 20.7109 25.3438 20.5352 25.3438 20.3398Z"
            :fill="current >= totalPage ? fillColor.disabled : fillColor.normal"
          />
        </svg>
      </router-link>
    </li>
    <li :disabled="current === totalPage">
      <router-link :to="{ query: { page: totalPage } }">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.8184 20.3398C22.8184 20.5352 22.7402 20.7109 22.5938 20.8574L15.2402 27.9375C15.1035 28.0742 14.9277 28.1523 14.7227 28.1523C14.3223 28.1523 14.0098 27.8398 14.0098 27.4297C14.0098 27.2246 14.0879 27.0586 14.2148 26.9219L21.0605 20.3398L14.2148 13.748C14.0879 13.6113 14.0098 13.4355 14.0098 13.2402C14.0098 12.8301 14.3223 12.5273 14.7227 12.5273C14.9277 12.5273 15.1035 12.5957 15.2402 12.7324L22.5938 19.8125C22.7402 19.959 22.8184 20.1348 22.8184 20.3398Z"
            :fill="
              current === totalPage ? fillColor.disabled : fillColor.normal
            "
          />
          <line
            x1="0.6"
            y1="-0.6"
            x2="16.4"
            y2="-0.6"
            transform="matrix(5.9139e-08 1 1 -5.9139e-08 26 12)"
            :stroke="
              current === totalPage ? fillColor.disabled : fillColor.normal
            "
            stroke-width="1.2"
            stroke-linecap="round"
          />
        </svg>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
  },

  computed: {
    current: {
      set(val) {
        if (val > 0) {
          this.$emit("input", Number(val));
          this.$router.push({ query: { page: Number(val) } });
        }
      },
      get() {
        return this.value;
      },
    },

    fillColor() {
      return {
        normal: "var(--s-primary)",
        disabled: "var(--s-gray-light)",
      };
    },
  },

  created() {
    if (this.$route.query?.page) {
      this.$emit("input", Number(this.$route.query?.page));
    }
  },

  watch: {
    $route(val, prev) {
      if (val.query?.page !== prev.query?.page) {
        this.$emit("input", Number(val.query?.page));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.s-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 0;

  .s-pagination__center {
    line-height: 40px;
    display: flex;
    align-items: center;
    input {
      background: #ffffff;
      border: 1px solid #e5e5ea;
      width: 40px;
      text-align: center;
    }

    span {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #e5e5ea;
      font-family: "SF UI Text";
      font-style: normal;
      font-weight: 400;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  :not(:last-child) {
    margin-right: 12px;
  }
}

.s-pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.125rem;
  color: var(--s-gray-dark);
  border: 1px solid var(--s-primary);
  background-color: var(--s-white);
}

.s-pagination li[disabled] a {
  pointer-events: none;
  border: 1px solid var(--s-gray-light);
}
</style>
