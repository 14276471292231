import { mapActions } from "vuex";

const MODULE_CODE = 'liff_point'

export default {
  methods: {
    ...mapActions({
      fetchMe: 'liffAuth/fetchMe',
    }),
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
  }
}
